* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Gilroy-Medium' !important;
}

@font-face {
  font-family: 'Gilroy-Regular';
  src: url('/src/assets/font/gilroy/Gilroy-Regular.ttf');
}

@font-face {
  font-family: 'Gilroy-Medium';
  src: url('/src/assets/font/gilroy/Gilroy-Medium.ttf');
}

.Gilroy_SemiBold {
  font-family: 'Gilroy-SemiBold' !important;
}

@font-face {
  font-family: 'Gilroy-SemiBold';
  src: url('/src/assets/font/gilroy/Gilroy-SemiBold.ttf');
}

.Gilroy_Bold {
  font-family: 'Gilroy-Bold' !important;
}

@font-face {
  font-family: 'Gilroy-Bold';
  src: url('/src/assets/font/gilroy/Gilroy-Bold.ttf');
}

.Gilroy_ExtraBold {
  font-family: 'Gilroy-ExtraBold' !important;
}

@font-face {
  font-family: 'Gilroy-ExtraBold';
  src: url('/src/assets/font/gilroy/Gilroy-ExtraBold.ttf');
}

a {
  color: #00BFF5 !important;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.background_Image {
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat !important;
  background-position: center;
  background-size: cover !important;
  background: linear-gradient(to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0)), url("./assets/images/bg.png");
}

/*--------------------- 
        Scrollbar css 
----------------------------------*/
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  position: absolute;
  /* / display: none; / */
  scrollbar-gutter: stable both-edges;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #e4e4e4;
  border-radius: 10px;
  border: 2px solid transparent;
}

/*--------------------- 
        Scrollbar css 
----------------------------------*/

.tooltip_custom {
  z-index: 9999999 !important;
}

/* .scrollable-content {
  margin-left: auto;
  margin-right: auto;
}
.scrollable-content:hover,
.scrollable-content:active,
.scrollable-content:focus {
  overflow-y: scroll;
} */



/*--------------------- 
        MUI css 
----------------------------------*/
.sidebar_text span {
  font-size: 20px !important;
  line-height: 1 !important;
}

.active_sidebar {
  background: #00BFF5 !important;
}

.user_icon {
  width: 30px;
  height: 30px;
}

.menu_icon {
  font-size: 20px;
}

.tab_button {
  border-radius: 30px !important;
}

.table_select fieldset {
  border: unset !important;
}

.table_select .MuiSelect-select {
  line-height: 2 !important;
  box-shadow: unset !important;
  padding: unset !important;
  padding-right: 25px !important;
  font-size: 14px !important;
  font-weight: 700 !important;
}

.table_select .MuiSelect-icon {
  top: calc(50% - 0.6em) !important;
}

.table_top_select fieldset {
  border: unset !important;
}

.base-Popper-root {
  z-index: 9999 !important;
}

.table_icon {
  width: 32px;
  height: 32px;
  padding: 6px;
}

.image_upload_icon {
  width: 94px;
  height: 94px;
}

@media (max-width:600px) {
  .table_icon {
    width: 25px !important;
    height: 25px !important;
    padding: 5px !important;
  }

  .sidebar_text span {
    font-size: 16px !important;
  }

  .download_button .MuiButton-startIcon {
    margin: auto !important;
  }

  .user_icon {
    width: 26px;
    height: 26px;
  }

  .menu_icon {
    font-size: 21px;
  }

  .image_upload_icon {
    width: 74px;
    height: 74px;
  }
}

/*--------------------- 
        MUI css 
----------------------------------*/

/*--------------------- 
        DateRange css 
----------------------------------*/
.date_range {
  width: max-content !important;
}

.date_range button {
  background: #fff;
  color: #00BFF5;
  font-weight: 400;
  border: unset !important;
  border-radius: 4px;
  padding: 10px 15px;
  text-align: start;
  font-size: 22px;
  width: 100%;
  /* / box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25); / */
}

.date_range_width button {
  width: unset !important;
  background: #00BFF5 !important;
  color: #fff !important;
}

.daterangepicker .ranges li.active {
  background-color: #00BFF5 !important;
}

.daterangepicker.opensright:before,
.daterangepicker.opensright:after {
  right: 20px !important;
  left: unset !important;
  display: none !important;
}

.show-calendar {
  right: 20px !important;
  overflow: scroll;
  height: 390px;
}

.daterangepicker .ranges ul {
  width: 100% !important;
}

.applyBtn {
  background: #00BFF5 !important;
  border: 1px solid #00BFF5 !important;
}

.daterangepicker td.in-range {
  background-color: #00BFF520 !important;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #00BFF5 !important;
}

.date_range_icon {
  padding: 5px 10px;
}

.date_range_icon svg {
  font-size: 30px;
}

.ranges ul li {
  font-size: 20px !important;
  padding: 12px 12px !important;
}

.available {
  font-size: 22px !important;
  width: 40px !important;
  height: 35px !important;
}

.daterangepicker .drp-calendar {
  /* / display: none; / */
  max-width: 300px !important;
}

.cancelBtn,
.applyBtn,
.drp-selected,
.daterangepicker .calendar-table th,
.daterangepicker .calendar-table td {
  font-size: 22px !important;
}

@media (max-width:600px) {
  .daterangepicker {
    right: 20px !important;
  }

  .date_range button {
    padding: 13px 10px;
    font-size: 14px;
  }

  .date_range_icon {
    padding: 3px;
    display: none !important;
  }

  .date_range_icon svg {
    font-size: 18px;
  }
}





/*--------------------- 
        DateRange css 
----------------------------------*/


/*--------------------- 
        Radix UI css 
----------------------------------*/
.notificationDropDownTrigger {
  background: transparent;
  display: flex;
  border: unset !important;
  outline: unset !important;
}

[data-radix-popper-content-wrapper] {
  top: 9px !important;
  z-index: 9999 !important;
}

.notificationDropDownContent {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.12);
  z-index: 1000;
  padding: 7px;
}

.notificationDropDownMenu {
  border: unset !important;
  outline: unset !important;
  font-size: 14px !important;
  cursor: pointer;
}

/* .notificationDropDownMenu:hover {
  background: #00AD6F15;
} */

/*--------------------- 
        Radix UI css 
----------------------------------*/
.add_person_hours {
  position: relative;
  margin-top: 300px;
  margin-left: 100px;
}

.add_person_hours tr th,
.add_person_hours tr td {
  text-align: center;
  padding: 10px 15px;
  border: 2px solid #00BFF5;
  font-size: 30px;
  /* / font-weight: 600; / */
}

.add_person_hours {
  background: #fff;
}

.MuiAutocomplete-option {
  font-size: 25px !important;
}

.childdata {
  display: flex;
  flex-wrap: wrap;
  margin-top: -250px;
}

.add_person_hours_education tr th,
.add_person_hours_education tr td {
  text-align: center;
  padding: 10px 15px;
  border: 2px solid #00BFF5;
  font-size: 30px;
  /* / font-weight: 600; / */
}

.add_person_hours_education {
  background: #fff;
  position: relative;
}

/* display: grid;
grid-template-columns: repeat(3, 1fr); */

.white-box {
  background-color: white;
  padding: 0px 10px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: fit-content;
}

.deletebutton {
  padding: 5px 20px;
  border-radius: 5px;
  border: none;
  background-color: white;
  color: red;
  font-size: 38px;
  text-align: center;
}

.Dotdeletebtn {
  padding: 0px 5px 5px 5px;
  background-color: #ff3939;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 20px;
  font-weight: 900;
}

.closeicon {
  font-size: 45px;
  cursor: pointer;
  background-color: white;
  margin: 6px;
  color: black;
  position: sticky;
  left: 50%;
  z-index: 9999;
}

.MuiTablePagination-displayedRows {
  font-size: 25px !important;
}

.MuiTablePagination-actions svg {
  font-size: 2.5rem;
  /* Adjust this value to increase or decrease the size */
}
.backgroundsetting{
display: flex;
background-color: #e3e3e3;
width: fit-content !important;
padding: 10px;
gap: 10px;
}

.fontsize{
  font-size: 30px !important;
  font-weight: 600 !important;
}